.loader svg {
  animation: rotation 700ms infinite linear;
  fill: #e20074;
  stroke: #e20074;
}

.loader svg path {
  fill: #e20074;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
