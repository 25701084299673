body {
  background-color: #ffffff;
  font-family: "TeleGroteskNext-Regular", Arial, sans-serif;
  font-size: 16px;
  color: #262626;
  line-height: 1.5rem;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
}

.white {
  color: #fff;
}

.magenta {
  color: #e20074;
}

.custom-tooltip {
  position: relative;
}

.custom-tooltip .tooltip-text {
  position: absolute;
  z-index: 1;
  top: 0;
  width: 250px;
  left: 20px;
  color: white;
  background: black;
  padding: 10px;
  font-size: 18px;
  display: none;
}

.custom-tooltip i:hover + .tooltip-text {
  display: block;
}

.bg1 {
  background-image: url("/images/header_with_text_mobile.png");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
}

.bg2 {
  background-image: url("/images/thanks_mobile.png");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
}

.hero1 {
  color: #fff;
  text-align: center;
  padding-top: 230px;
  padding-bottom: 30px;
}

.hero2 {
  color: #fff;
  padding-top: 150px;
  padding-bottom: 30px;
  text-align: center;
}

.hero3 {
  padding-top: 20px;
  text-align: center;
}

.full {
  min-height: 720px;
}

.two-thirds {
  min-height: 480px;
}

.half {
  min-height: 360px;
}

.quarter {
  min-height: 180px;
}

.magenta-strip {
  text-align: center !important;
  background-color: #e20074;
  color: #fff;
}

.teleicon {
  font-family: "TeleIcon-Outline";
  /*    color: #e20074;*/
  font-size: 1rem;
  margin-left: 5px;
  text-decoration: none !important;
}

.legal {
  font-family: "Arial";
  font-size: 12pt;
  color: #6a6a6a;
}

.faq-card-body {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.faq-card-text {
  padding-left: 2rem;
}

.recaptcha-container {
  width: 304px;
  height: 78px;
  margin: 0 auto;
}

.exclusive-header {
  font-size: 2rem;
  font-weight: 800;
}

h1 {
  font-family: "TeleGroteskNext-Ultra", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 3.2rem;
  letter-spacing: -2px;
  line-height: 3.2rem;
}

.font-teleNeoWeb{
  font-family: "TeleNeoWeb-ExtraBold", sans-serif;
}

h3 {
  font-family: "TeleGroteskNext-Ultra", sans-serif;
  font-style: normal;
  font-size: 1.3rem;
  text-align: center;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-style: normal;
  font-weight: 800;
}

h2,
h4,
h5,
h6 {
  font-style: normal;
  font-weight: 800;
}

p {
  font-size: 1rem;
}

ul,
ol {
  text-align: left;
  font-size: 1rem;
}

a:link {
  color: #e20074;
  font-weight: 600;
  text-decoration: underline;
}

a:hover,
a:focus,
a:visited,
a:active {
  color: #e20074;
  text-decoration: underline;
}

/* COMMON ELEMENTS */

.form-control {
  height: 45px;
  min-width: 120px;
  padding-left: 20px;
  border-radius: 0px;
  border: 1px solid darkgray;
  font-size: 1rem;
  background-color: #f5f5f5;
}

.nav-link {
  padding: 0.5rem 1rem 0.5rem 0;
}

.nav-link:hover {
  text-decoration: underline;
}

/* COMMON ELEMENTS */

.pointer {
  cursor: pointer;
}

.text-magenta {
  color: #e20074;
}

/* BUTTONS */

.btn {
  font-size: 1rem;
  padding: 8px 20px 8px 20px;
  min-width: 120px;
  border-radius: 0px;
  width: 250px;
  margin-bottom: 20px;
}

.btn-primary1,
.btn-primary1:hover,
.btn-primary1:focus,
.btn-primary1:active,
.btn-primary1:visited {
  color: #fff;
  border: none !important;
  border-radius: 0px;
  background-color: #e20074;
}

.btn-primary2,
.btn-primary2:hover,
.btn-primary2:focus,
.btn-primary2:active,
.btn-primary2:visited {
  color: #e20074;
  background-color: #fff;
  border: none !important;
  border-radius: 0px;
}

.btn-secondary,
.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary:active,
.btn-secondary:visited {
  color: #e20074;
  background-color: transparent;
  border: 1px solid #e20074;
  border-radius: 0px;
}

/* BUTTONS */

/* Footer */

footer {
  background-color: #999;
}

footer p {
  font-size: 0.75rem;
  line-height: 140%;
}

footer a:link {
  font-size: 0.75rem;
  font-weight: bold;
  font-weight: bold;
  text-decoration: none;
}

/* Footer */

/* Media Queries */

@media (min-width: 576px) {
  .bg1 {
    background-image: url("/images/header_with_text_desktop.png");
    background-position: center;
  }

  .bg2 {
    background-image: url("/images/thanks_desktop.png");
  }
}
@media (max-width: 767px) {
  .hookupLookup {
    text-align: center;
  }
  .faqHead {
    margin-bottom: 10px;
  }
  .faq-sub-head {
    text-align: "left";
  }
  .faq-card-body {
    text-align: left;
  }
  .faq-card-text {
    padding-left: 0px;
    text-align: left;
    margin-left: 15px;
  }
  .resendHead {
    text-align: center;
  }
  .resendBody {
    text-align: center;
  }
  .row.no-gutters2 {
    margin-right: 0;
    margin-left: 0;
  }
  .row.no-gutters2 > [class^="col-"],
  .row.no-gutters2 > [class*=" col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .hero1 {
    padding-top: 150px;
    padding-bottom: 150px;
  }

  .hero2 {
    padding-bottom: 150px;
    text-align: left;
  }

  .hero3 {
    text-align: left;
  }

  .btn {
    width: auto;
    margin-bottom: 30px;
  }

  .exclusive-header {
    font-style: normal;
    font-size: 3.2rem;
  }

  h1 {
    font-family: "TeleGroteskNext-Ultra", sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 4rem;
    letter-spacing: -2px;
    line-height: 4rem;
  }

  .font-teleNeoWeb{
    font-family: "TeleNeoWeb-ExtraBold", sans-serif;
  }

  h3 {
    font-family: "TeleGroteskNext-Ultra", sans-serif;
    font-style: normal;
    font-size: 1.75rem;
    text-align: left;
  }

  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    font-style: normal;
    font-weight: 800;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-style: normal;
    font-weight: 800;
  }

  p {
    font-size: 16px;
  }

  ul {
    font-size: 1.1rem;
  }
}

@media screen and (max-width: 600px) {
  #tracker table {
    border: 0;
  }

  #tracker table thead {
    display: none;
  }

  #tracker table tr {
    margin-bottom: 20px;
    display: block;
    border: 2px solid lightgrey;
    box-shadow: 2px 2px 1px lightgray;
  }

  #tracker table td {
    display: block;
    text-align: right;
    font-size: 0.75rem;
  }

  #tracker table td:last-child {
    border-bottom: 0;
  }

  #tracker table td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
  }
}
