/* Thin */
@font-face {
    font-family: "TeleGroteskNext-Thin";
    src: url("/fonts/TeleGroteskNext-Thin.eot");
    src: url("/fonts/TeleGroteskNext-Thin.eot?#iefix") format("embedded-opentype"),
        url("/fonts/TeleGroteskNext-Thin.woff") format("woff"),
        url("/fonts/TeleGroteskNext-Thin.woff2") format("woff2"),
        url("/fonts/TeleGroteskNext-Thin.ttf") format("truetype"),
        url("/fonts/TeleGroteskNext-Thin.svg#TeleGroteskNext-Thin") format("svg");
    font-weight: normal;
    font-style: normal;
  }
  
  /* Regular */
  @font-face {
    font-family: "TeleGroteskNext-Regular";
    src: url("/fonts/TeleGroteskNext-Regular.eot");
    src: url("/fonts/TeleGroteskNext-Regular.eot?#iefix")
        format("embedded-opentype"),
        url("/fonts/TeleGroteskNext-Regular.woff") format("woff"),
        url("/fonts/TeleGroteskNext-Regular.woff2") format("woff2"),
        url("/fonts/TeleGroteskNext-Regular.ttf") format("truetype"),
        url("/fonts/TeleGroteskNext-Regular.svg#TeleGroteskNext-Regular")
        format("svg");
    font-weight: normal;
    font-style: italic;
  }
  
  /* Regular Italic */
  @font-face {
    font-family: "TeleGroteskNext-RegularItalic";
    src: url("/fonts/TeleGroteskNext-RegularItalic.eot");
    src: url("/fonts/TeleGroteskNext-RegularItalic.eot?#iefix")
        format("embedded-opentype"),
        url("/fonts/TeleGroteskNext-RegularItalic.woff") format("woff"),
        url("/fonts/TeleGroteskNext-RegularItalic.woff2") format("woff2"),
        url("/fonts/TeleGroteskNext-RegularItalic.ttf") format("truetype"),
        url("/fonts/TeleGroteskNext-RegularItalic.svg#TeleGroteskNext-RegularItalic")
        format("svg");
    font-weight: normal;
    font-style: normal;
  }
  
  /* Medium */
  @font-face {
    font-family: "TeleGroteskNext-Medium";
    src: url("/fonts/TeleGroteskNext-Medium.eot");
    src: url("/fonts/TeleGroteskNext-Medium.eot?#iefix") format("embedded-opentype"),
        url("/fonts/TeleGroteskNext-Medium.woff") format("woff"),
        url("/fonts/TeleGroteskNext-Medium.woff2") format("woff2"),
        url("/fonts/TeleGroteskNext-Medium.ttf") format("truetype"),
        url("/fonts/TeleGroteskNext-Medium.svg#TeleGroteskNext-Medium") format("svg");
    font-weight: normal;
    font-style: italic;
  }
  
  /* Medium Italic */
  @font-face {
    font-family: "TeleGroteskNext-MediumItalic";
    src: url("/fonts/TeleGroteskNext-MediumItalic.eot");
    src: url("/fonts/TeleGroteskNext-MediumItalic.eot?#iefix")
        format("embedded-opentype"),
        url("/fonts/TeleGroteskNext-MediumItalic.woff") format("woff"),
        url("/fonts/TeleGroteskNext-MediumItalic.woff2") format("woff2"),
        url("/fonts/TeleGroteskNext-MediumItalic.ttf") format("truetype"),
        url("/fonts/TeleGroteskNext-MediumItalic.svg#TeleGroteskNext-MediumItalic")
        format("svg");
    font-weight: normal;
    font-style: italic;
  }
  
  /* Bold */
  @font-face {
    font-family: "TeleGroteskNext-Bold";
    src: url("/fonts/TeleGroteskNext-Bold.eot");
    src: url("/fonts/TeleGroteskNext-Bold.eot?#iefix") format("embedded-opentype"),
        url("/fonts/TeleGroteskNext-Bold.woff") format("woff"),
        url("/fonts/TeleGroteskNext-Bold.woff2") format("woff2"),
        url("/fonts/TeleGroteskNext-Bold.ttf") format("truetype"),
        url("/fonts/TeleGroteskNext-Bold.svg#TeleGroteskNext-Bold") format("svg");
    font-weight: normal;
    font-style: italic;
  }
  
  /* Bold Italic */
  @font-face {
    font-family: "TeleGroteskNext-BoldItalic";
    src: url("/fonts/TeleGroteskNext-BoldItalic.eot");
    src: url("/fonts/TeleGroteskNext-BoldItalic.eot?#iefix")
        format("embedded-opentype"),
        url("/fonts/TeleGroteskNext-BoldItalic.woff") format("woff"),
        url("/fonts/TeleGroteskNext-BoldItalic.woff2") format("woff2"),
        url("/fonts/TeleGroteskNext-BoldItalic.ttf") format("truetype"),
        url("/fonts/TeleGroteskNext-BoldItalic.svg#TeleGroteskNext-BoldItalic")
        format("svg");
    font-weight: normal;
    font-style: italic;
  }
  
  /* Ultra */
  @font-face {
    font-family: "TeleGroteskNext-Ultra";
    src: url("/fonts/TeleGroteskNext-Ultra.eot");
    src: url("/fonts/TeleGroteskNext-Ultra.eot?#iefix") format("embedded-opentype"),
        url("/fonts/TeleGroteskNext-Ultra.woff") format("woff"),
        url("/fonts/TeleGroteskNext-Ultra.woff2") format("woff2"),
        url("/fonts/TeleGroteskNext-Ultra.ttf") format("truetype"),
        url("/fonts/TeleGroteskNext-Ultra.svg#TeleGroteskNext-Ultra") format("svg");
    font-weight: 800;
    font-style: italic;
  }

  /* Thin */
  @font-face {
    font-family: "TeleNeoWeb-Thin";
    src: url("/fonts/TeleNeoWeb-Thin.eot");
    src: url("/fonts/TeleNeoWeb-Thin.eot?#iefix") format("embedded-opentype"),
        url("/fonts/TeleNeoWeb-Thin.woff") format("woff"),
        url("/fonts/TeleNeoWeb-Thin.woff2") format("woff2"),
        url("/fonts/TeleNeoWeb-Thin.ttf") format("truetype"),
        url("/fonts/TeleNeoWeb-Thin.svg#TeleNeoWeb-Thin") format("svg");
    font-weight: normal;
    font-style: normal;
  }

  /* Regular */
  @font-face {
    font-family: "TeleNeoWeb-Regular";
    src: url("/fonts/TeleNeoWeb-Regular.eot");
    src: url("/fonts/TeleNeoWeb-Regular.eot?#iefix")
        format("embedded-opentype"),
        url("/fonts/TeleNeoWeb-Regular.woff") format("woff"),
        url("/fonts/TeleNeoWeb-Regular.woff2") format("woff2"),
        url("/fonts/TeleNeoWeb-Regular.ttf") format("truetype"),
        url("/fonts/TeleNeoWeb-Regular.svg#TeleNeoWeb-Regular")
        format("svg");
    font-weight: normal;
    font-style: italic;
  }

  /* Regular Italic */
  @font-face {
    font-family: "TeleNeoWeb-RegularItalic";
    src: url("/fonts/TeleNeoWeb-RegularItalic.eot");
    src: url("/fonts/TeleNeoWeb-RegularItalic.eot?#iefix")
        format("embedded-opentype"),
        url("/fonts/TeleNeoWeb-RegularItalic.woff") format("woff"),
        url("/fonts/TeleNeoWeb-RegularItalic.woff2") format("woff2"),
        url("/fonts/TeleNeoWeb-RegularItalic.ttf") format("truetype"),
        url("/fonts/TeleNeoWeb-RegularItalic.svg#TeleNeoWeb-RegularItalic")
        format("svg");
    font-weight: normal;
    font-style: normal;
  }

  /* Medium */
  @font-face {
    font-family: "TeleNeoWeb-Medium";
    src: url("/fonts/TeleNeoWeb-Medium.eot");
    src: url("/fonts/TeleNeoWeb-Medium.eot?#iefix") format("embedded-opentype"),
        url("/fonts/TeleNeoWeb-Medium.woff") format("woff"),
        url("/fonts/TeleNeoWeb-Medium.woff2") format("woff2"),
        url("/fonts/TeleNeoWeb-Medium.ttf") format("truetype"),
        url("/fonts/TeleNeoWeb-Medium.svg#TeleNeoWeb-Medium") format("svg");
    font-weight: normal;
    font-style: italic;
  }

  /* Medium Italic */
  @font-face {
    font-family: "TeleNeoWeb-MediumItalic";
    src: url("/fonts/TeleNeoWeb-MediumItalic.eot");
    src: url("/fonts/TeleNeoWeb-MediumItalic.eot?#iefix")
        format("embedded-opentype"),
        url("/fonts/TeleNeoWeb-MediumItalic.woff") format("woff"),
        url("/fonts/TeleNeoWeb-MediumItalic.woff2") format("woff2"),
        url("/fonts/TeleNeoWeb-MediumItalic.ttf") format("truetype"),
        url("/fonts/TeleNeoWeb-MediumItalic.svg#TeleNeoWeb-MediumItalic")
        format("svg");
    font-weight: normal;
    font-style: italic;
  }

  /* Bold */
  @font-face {
    font-family: "TeleNeoWeb-Bold";
    src: url("/fonts/TeleNeoWeb-Bold.eot");
    src: url("/fonts/TeleNeoWeb-Bold.eot?#iefix") format("embedded-opentype"),
        url("/fonts/TeleNeoWeb-Bold.woff") format("woff"),
        url("/fonts/TeleNeoWeb-Bold.woff2") format("woff2"),
        url("/fonts/TeleNeoWeb-Bold.ttf") format("truetype"),
        url("/fonts/TeleNeoWeb-Bold.svg#TeleNeoWeb-Bold") format("svg");
    font-weight: normal;
    font-style: italic;
  }

  /* Bold Italic */
  @font-face {
    font-family: "TeleNeoWeb-BoldItalic";
    src: url("/fonts/TeleNeoWeb-BoldItalic.eot");
    src: url("/fonts/TeleNeoWeb-BoldItalic.eot?#iefix")
        format("embedded-opentype"),
        url("/fonts/TeleNeoWeb-BoldItalic.woff") format("woff"),
        url("/fonts/TeleNeoWeb-BoldItalic.woff2") format("woff2"),
        url("/fonts/TeleNeoWeb-BoldItalic.ttf") format("truetype"),
        url("/fonts/TeleNeoWeb-BoldItalic.svg#TeleNeoWeb-BoldItalic")
        format("svg");
    font-weight: normal;
    font-style: italic;
  }

  /* ExtraBold */
  @font-face {
    font-family: "TeleNeoWeb-ExtraBold";
    src: url("/fonts/TeleNeoWeb-ExtraBold.eot");
    src: url("/fonts/TeleNeoWeb-ExtraBold.eot?#iefix") format("embedded-opentype"),
        url("/fonts/TeleNeoWeb-ExtraBold.woff") format("woff"),
        url("/fonts/TeleNeoWeb-ExtraBold.woff2") format("woff2"),
        url("/fonts/TeleNeoWeb-ExtraBold.ttf") format("truetype"),
        url("/fonts/TeleNeoWeb-ExtraBold.svg#TeleNeoWeb-ExtraBold") format("svg");
    font-weight: 800;
    font-style: italic;
  }
  
  /* Telecon Outline */
  @font-face {
    font-family: "TeleIcon-Outline";
    src: url("/fonts/TeleIcon-Outline.eot");
    src: url("/fonts/TeleIcon-Outline.eot?#iefix") format("embedded-opentype"),
        url("/fonts/TeleIcon-Outline.woff") format("woff"),
        url("/fonts/TeleIcon-Outline.woff2") format("woff2"),
        url("/fonts/TeleIcon-Outline.ttf") format("truetype"),
        url("/fonts/TeleIcon-Outline.svg#TeleIcon-Outline") format("svg");
    font-weight: normal;
    font-style: italic;
  }
  
  /* Telecon Solid */
  @font-face {
    font-family: "TeleIcon-Solid";
    src: url("/fonts/TeleIcon-Solid.eot");
    src: url("/fonts/TeleIcon-Solid.eot?#iefix") format("embedded-opentype"),
        url("/fonts/TeleIcon-Solid.woff") format("woff"),
        url("/fonts/TeleIcon-Solid.woff2") format("woff2"),
        url("/fonts/TeleIcon-Solid.ttf") format("truetype"),
        url("/fonts/TeleIcon-Solid.svg#TeleIcon-Outline") format("svg");
    font-weight: normal;
    font-style: italic;
  }
  